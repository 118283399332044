<template>
  <div>
    <div>
      <div class="row">
        <div class="col-3">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-3">
          <base-input>
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              v-model="product_status"
              :placeholder="$t('COMMON.STATUS')"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in PRODUCT_STATUS"
                :key="key"
                :label="$t(`PRODUCTS.STATUS_${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div
          class="col-3"
          v-if="
            !filterSupplier &&
            $currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)
          "
        >
          <base-input>
            <supplier-selector
              v-if="
                !filterSupplier &&
                $currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)
              "
              :filterOrganization="filterOrganization"
              @supplierChanged="(supplierId) => (selectedSupplier = supplierId)"
            />
          </base-input>
        </div>

        <div class="col-3">
          <base-input>
            <category-group-selector
              :allowNone="true"
              :showAll="false"
              :isOnlyModels="false"
              :placeholder="$t('COMMON.CATEGORIES')"
              @categoryChanged="
                (categoryId) => {
                  selectedCategory = categoryId;
                }
              "
            />
          </base-input>
        </div>

        <div class="col-3">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>
      <el-table
        class="table-responsive align-items-center table-flush supplier-product-list"
        header-row-class-name="thead-light"
        :data="supplierProducts"
        type="expand"
        row-key="id"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="expand">
          <template slot-scope="props">
            <form
              class=""
              @submit.prevent=""
              v-if="supplierProductsModel[props.row.id]"
            >
              <base-input
                :label="`${$t('COMMON.TAX_GROUPS')}`"
                :placeholder="$t('COMMON.TAX_GROUPS')"
                :disabled="!supplierProductsModel[props.row.id].custom_taxation"
              >
                <tax-groups-selector
                  :taxGroups="supplierProductsModel[props.row.id].taxGroups"
                  @taxGroupsChanged="
                    (taxGroups) => {
                      supplierProductsModel[props.row.id].taxGroups = taxGroups;
                      updateSupplierProductDebounced(props.row.id);
                    }
                  "
                  :disabled="
                    !supplierProductsModel[props.row.id].custom_taxation
                  "
                />
              </base-input>

              <base-input
                :label="$t('COMMON.EXCERPT')"
                :placeholder="$t('COMMON.EXCERPT')"
                input-classes="form-control-alternative"
              >
                <html-editor
                  v-model="supplierProductsModel[props.row.id].excerpt"
                  @change="updateSupplierProductDebounced(props.row.id)"
                >
                </html-editor>
              </base-input>
            </form>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterProduct"
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="400px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterOrganization="filterOrganization"
                :product="row.product.id"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                :filterProductsNotInSupplierId="supplier ? supplier.id : null"
                :filterCanBePurchased="true"
                @productChanged="
                  (productId, product) => {
                    supplierProductsModel[row.id].product.id = productId;
                    supplierProductsModel[row.id].sku = product.sku;
                    supplierProductsModel[row.id].selling_price =
                      product.selling_price;
                    supplierProductsModel[row.id].buying_price =
                      product.buying_price;
                    supplierProductsModel[row.id].taxGroups = product.taxGroups;
                    updateSupplierProductDebounced(row.id);
                  }
                "
              />
            </base-input>
            <object-link v-if="row.id > 0" :object="row.product" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterSupplier"
          :label="$t('COMMON.SUPPLIER')"
          prop="supplier.name"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <supplier-selector
                :filterOrganization="filterOrganization"
                :supplier="row.supplier.id"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                :filterIdsNotIn="existingSuppliers"
                @supplierChanged="
                  (supplierId) => {
                    supplierProductsModel[row.id].supplier.id = supplierId;
                    updateSupplierProductDebounced(row.id);
                  }
                "
              />
            </base-input>
            <object-link v-if="row.id > 0" :object="row.supplier" />
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('PRODUCTS.SKU')"
          prop="sku"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :placeholder="$t('PRODUCTS.SKU')"
              v-model="supplierProductsModel[row.id].sku"
              input-classes="form-control-alternative"
              @change="updateSupplierProductDebounced(row.id)"
            >
            </base-input>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('PRODUCTS.SUPPLIER_CODE')"
          prop="supplier_code"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :placeholder="$t('PRODUCTS.SUPPLIER_CODE')"
              v-model="supplierProductsModel[row.id].supplier_code"
              input-classes="form-control-alternative"
              @change="updateSupplierProductDebounced(row.id)"
            >
            </base-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('PRODUCTS.CUSTOM')"
          prop="sku"
          min-width="150px"
        >
          <template v-slot="{ row }">
            <base-checkbox
              v-model="supplierProductsModel[row.id].custom_selling_price"
              class=""
              @input="updateSupplierProductDebounced(row.id)"
            >
              <span class="form-control-label">
                {{ $t("PRODUCTS.CUSTOM_SELLING_PRICE") }}
              </span>
            </base-checkbox>
            <base-checkbox
              v-model="supplierProductsModel[row.id].custom_buying_price"
              class=""
              @input="updateSupplierProductDebounced(row.id)"
            >
              <span class="form-control-label">
                {{ $t("PRODUCTS.CUSTOM_BUYING_PRICE") }}
              </span>
            </base-checkbox>
            <base-checkbox
              v-model="supplierProductsModel[row.id].custom_taxation"
              class=""
              @input="updateSupplierProductDebounced(row.id)"
            >
              <span class="form-control-label">
                {{ $t("PRODUCTS.CUSTOM_TAXATION") }}
              </span>
            </base-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.SELLING_PRICE')"
          prop="price"
          min-width="150px"
        >
          <template v-slot="{ row }">
            <base-input
              class="my-5"
              :placeholder="$t('COMMON.SELLING_PRICE')"
              v-model="supplierProductsModel[row.id].selling_price"
              input-classes="form-control-alternative"
              :disabled="!supplierProductsModel[row.id].custom_selling_price"
              @change="updateSupplierProductDebounced(row.id)"
            />
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.BUYING_PRICE')"
          prop="price"
          min-width="150px"
        >
          <template v-slot="{ row }">
            <base-input
              class="my-5"
              :placeholder="$t('COMMON.BUYING_PRICE')"
              v-model="supplierProductsModel[row.id].buying_price"
              input-classes="form-control-alternative"
              :disabled="!supplierProductsModel[row.id].custom_buying_price"
              @change="updateSupplierProductDebounced(row.id)"
            />
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="120px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Loading"
              placement="top"
              v-if="supplierProductsModel[row.id].loading"
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
            >
              <a
                type="text"
                @click="deleteSupplierProduct(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3">
        <div v-if="loading" class="col-12 text-center">
          <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>
        <base-button
          v-if="!loading"
          type="info"
          class="btn-sm mr-1 col-12"
          style="width: 100%"
          @click="
            () => {
              addLine();
            }
          "
        >
          <i class="fas fa-plus"></i>
          {{ addLineText }}
        </base-button>
      </div>
    </div>

    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import SupplierSelector from "@/components/SupplierSelector.vue";
import ProductSelector from "@/components/ProductSelector.vue";
import { PRODUCT_STATUS, STATUS_ACTIVE } from "@/constants/products";
import TaxGroupsSelector from "../TaxGroupsSelector.vue";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";

export default {
  name: "supplier-product-list",

  components: {
    SupplierSelector,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    HtmlEditor,
    TaxGroupsSelector,
    CategoryGroupSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    addLineText: {
      type: String,
      description: "Text for add line",
    },
    product: {
      type: Object,
      default: null,
      description: "Product",
    },
    supplier: {
      type: Object,
      default: null,
      description: "Supplier",
    },
    filterSupplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
    filterProduct: {
      type: String,
      default: null,
      description: "Product id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: "",
      selectedRows: [],
      sort: "created_at",
      total: 0,
      product_status: STATUS_ACTIVE,
      PRODUCT_STATUS,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      supplierProducts: [],
      loading: true,
      selectedProduct: null,
      selectedSupplier: null,
      selectedCategory: null,
      supplierProductsModel: {},
      existingSuppliers: [],
      existingProducts: [],
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    filterSupplier: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    selectedProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    supplierProducts: {
      handler: "refreshSupplierProducts",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: false,
    },
    selectedSupplier: {
      handler: "getListDebounced",
      immediate: false,
    },
    filterProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    product_status: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCategory: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    updateSupplierProductDebounced: _.debounce(function (id) {
      this.updateSupplierProduct(id);
    }, 300),

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
            ...(this.product_status ? { status: this.product_status } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "supplier,product,taxGroups",
        };
        if (this.filterSupplier) {
          params = {
            ...params,
            filter: { ...params.filter, supplier: this.filterSupplier },
          };
        }
        if (this.selectedSupplier) {
          params = {
            ...params,
            filter: { ...params.filter, supplier: this.selectedSupplier },
          };
        }
        if (this.selectedProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.selectedProduct,
            },
          };
        }
        if (this.filterProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.filterProduct,
            },
          };
        }
        if (this.selectedCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              productInCategory: this.selectedCategory,
            },
          };
        }

        await this.$store.dispatch("supplierProducts/list", params);
        this.supplierProducts = this.$store.getters["supplierProducts/list"];

        this.existingSuppliers = this.supplierProducts.map((item) => {
          return item.supplier.id;
        });
        this.total = this.$store.getters["supplierProducts/listTotal"];
        this.loading = false;
        this.scrollToTop();
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    refreshSupplierProducts() {
      const supplierProductsModelData = {};
      for (const item of this.supplierProducts) {
        supplierProductsModelData[item.id] = _.cloneDeep(item);
        supplierProductsModelData[item.id].loading = false;
      }
      this.supplierProductsModel = supplierProductsModelData;
    },

    addLine() {
      let newLinesIds = Object.values(this.supplierProducts)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;
      const newSupplierProduct = {
        type: "supplier-products",
        id: newLineId,
        sku: this.product ? this.product.sku : null,
        selling_price: this.product ? this.product.selling_price : 0,
        buying_price: this.product ? this.product.buying_price : 0,
        taxGroups: this.product?.taxGroups ?? [],
        custom_selling_price: false,
        custom_buying_price: false,
        custom_taxation: false,
        relationshipNames: ["supplier", "product", "taxGroups"],
        supplier: {
          type: "suppliers",
          id: this.supplier ? this.supplier.id : null,
        },
        product: {
          type: "products",
          id: this.product ? this.product.id : null,
        },
        loading: false,
      };
      this.supplierProducts.push(_.cloneDeep(newSupplierProduct));
      this.supplierProductsModel[newLineId] = _.cloneDeep(newSupplierProduct);
    },

    async deleteSupplierProduct(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_SUPPLIER_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        await this.$store.dispatch("supplierProducts/destroy", row.id);
      }
      this.getList();
    },

    async updateSupplierProduct(id) {
      this.supplierProductsModel[id].loading = true;
      const supplierProductData = this.supplierProductsModel[id];

      if (supplierProductData.id > 0) {
        await this.$store.dispatch(
          "supplierProducts/update",
          supplierProductData
        );
      } else {
        if (supplierProductData.product.id && supplierProductData.supplier.id) {
          delete supplierProductData.id;
          await this.$store.dispatch(
            "supplierProducts/add",
            supplierProductData
          );
        }
      }
      this.getList();
    },

    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth", // For smooth scrolling
      });
    },
  },
};
</script>

<style>
.supplier-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
</style>
